<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Show</v-card-title>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'InvoiceShow'
}
</script>

<style scoped>

</style>
